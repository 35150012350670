// src/components/DetailsTab.js
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import OrganizationSelect from "../../../components/OrganizationSelect";
import { selectLoader, validateDetailsTabForm } from "../../../utils/Helpers";
import APIServices from "../../../services/APIServices";
import appToast, { TOAST_TYPE } from "../../../utils/AppToast";

const DetailsTab = ({
  openModal,
  setCurrentTab,
  setprojectDetails,
  organizationsList,
  orgloading,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [detailsId, setDetailsId] = useState("");
  const [formValues, setFormValues] = useState({
    projectName: "",
    organizationId: "",
    startDate: null,
    endDate: null,
    estimatedPrice: "",
  });
  const [isEditing, setEditing] = useState(false);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Only allow digits and period for decimal values
    const validValue = value.replace(/[^0-9.]/g, "");

    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: name == "estimatedPrice" ? `${validValue}` : value,
    }));

    // Remove errors dynamically when the user enters valid data
    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: "",
      });
    }
  };

  const handleDateChange = (name, date) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: date,
    }));

    // Remove errors dynamically when the user enters valid data
    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: "",
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const endpoint = isEditing ? `/project/${detailsId}` : "/project";

    if (!validateDetailsTabForm(formValues, setErrors)) {
      setLoading(true);
      // Format the dates to MM/DD/YYYY
      const formattedStartDate = formValues.startDate
        ? new Date(formValues.startDate).toLocaleDateString("en-US")
        : null;
      const formattedDueDate = formValues.endDate
        ? new Date(formValues.endDate).toLocaleDateString("en-US")
        : null;

      const formattedFormData = {
        ...formValues,
        startDate: formattedStartDate,
        endDate: formattedDueDate,
        estimatedPrice: Number(formValues.estimatedPrice),
      };

      try {
        const response = isEditing
          ? await APIServices.put(endpoint, formattedFormData)
          : await APIServices.post(endpoint, formattedFormData);

        if (response.project) {
          let projectDetails = response.project;
          setCurrentTab(2);
          setEditing(true);
          setDetailsId(projectDetails._id);
          setprojectDetails(projectDetails);
          // appToast(response.message, TOAST_TYPE.SUCCESS);
        } else {
          appToast(response.data.message, TOAST_TYPE.ERROR);
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    } else {
      console.log(errors);
    }
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="details-tab">
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-12">
            <div className="input-group">
              <input
                type="text"
                className={`form-control ${
                  errors.projectName ? "form-invalid" : ""
                }`}
                placeholder="Project Name"
                name="projectName"
                value={formValues.projectName}
                onChange={handleChange}
              />
              {errors.projectName && (
                <div className="error-message">{errors.projectName}</div>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <OrganizationSelect
              formValues={formValues}
              handleChange={handleChange}
              openModal={openModal}
              errors={errors}
              organizationsList={organizationsList}
              orgloading={orgloading}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div
              className={`radio_btn_section bg-white br-10 px-4 py-3 ${
                errors.endDate || errors.startDate ? "form-invalid" : ""
              }`}
            >
              <div className="radio_inner_sec align-items-center">
                <div className="text-left">
                  <label htmlFor="startDate" className="label-title">
                    Project Start
                  </label>
                </div>
                <DatePicker
                  selected={formValues.startDate}
                  onChange={(date) => handleDateChange("startDate", date)}
                  className="datepicker-days border-0"
                  placeholderText="+ Select Date"
                  dateFormat="MM/dd/yyyy"
                />
              </div>
              <div className="radio_inner_sec align-items-center mt-1">
                <div className="text-left">
                  <label htmlFor="dueDate" className="label-title">
                    Project Due
                  </label>
                </div>
                <DatePicker
                  selected={formValues.endDate}
                  onChange={(date) => handleDateChange("endDate", date)}
                  className="datepicker-days border-0"
                  placeholderText="+ Select Date"
                  // dateFormat="MMMM d, yyyy"
                  dateFormat="MM/dd/yyyy"
                />
              </div>
            </div>
            {errors.startDate
              ? errors.startDate && (
                  <div
                    className="error-message mb-1"
                    style={{ left: "12px", bottom: "0" }}
                  >
                    {errors.startDate}
                  </div>
                )
              : errors.endDate && (
                  <div
                    className="error-message mb-1"
                    style={{ left: "12px", bottom: "0" }}
                  >
                    {errors.endDate}
                  </div>
                )}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div
              className={`form-control form-control-padd ${
                errors.estimatedPrice ? "form-invalid" : ""
              }`}
            >
              <div className="row align-items-center estimated-value ">
                <div className="col label-title text-left">
                  Estimated Value of Project
                </div>
                <div className="col price-for-project">
                  <div className="form-group mb-0">
                    <input
                      type="text"
                      id="typeNumber"
                      className="project-price"
                      placeholder="$0.00"
                      name="estimatedPrice"
                      value={
                        formValues.estimatedPrice &&
                        `$${formValues.estimatedPrice}`
                      }
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              {errors.estimatedPrice && (
                <div className="error-message" style={{ left: "12px" }}>
                  {errors.estimatedPrice}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="btn-black-cover">
          <button className="btn-black" type="submit">
            {!loading ? (isEditing ? "Update" : "Continue") : selectLoader(35)}
          </button>
        </div>
      </form>
    </div>
  );
};

export default DetailsTab;
