import React, { useState } from "react";
import deleteBtn from "../../../assets/img/delete.svg";
import attechFile from "../../../assets/img/ios-attach.svg";
import filePic from "../../../assets/img/file-picture.svg";
const ScopeOfWork = () => {
  const [sections, setSections] = useState([
    {
      id: "projectOverview",
      label: "Project Overview",
      content:
        "The goal of this project is to develop a responsive website for a small business...",
      isEditable: false,
    },
    {
      id: "projectDeliverables",
      label: "Project Deliverables",
      content: "",
      isEditable: false,
    },
    {
      id: "projectWebPages",
      label: "The number of Web Pages",
      content: "",
      isEditable: false,
    },
    {
      id: "projectDesignRevisions",
      label: "The number of Design Revisions",
      content: "",
      isEditable: false,
    },
    {
      id: "projectTimeline",
      label: "Project Timeline",
      content: "",
      isEditable: false,
    },
    {
      id: "projectBudget",
      label: "Project Budget",
      content: "",
      isEditable: false,
    },
    {
      id: "paymentTerms",
      label: "Payment Terms",
      content: "",
      isEditable: false,
    },
  ]);

  // Handle text change
  const handleTextChange = (e, id) => {
    const updatedSections = sections.map((section) =>
      section.id === id ? { ...section, content: e.target.value } : section
    );
    setSections(updatedSections);
  };

  // Toggle edit mode
  const toggleEdit = (id) => {
    const updatedSections = sections.map((section) =>
      section.id === id
        ? { ...section, isEditable: !section.isEditable }
        : section
    );
    setSections(updatedSections);
  };

  // Delete section
  const handleDelete = (id) => {
    const updatedSections = sections.filter((section) => section.id !== id);
    setSections(updatedSections);
  };

  // Add new section
  const addNewSection = () => {
    const newSection = {
      id: `newSection${sections.length + 1}`,
      label: `New Section ${sections.length + 1}`,
      content: "",
      isEditable: true,
    };
    setSections([...sections, newSection]);
  };

  // Handle form submission
  const handleSubmit = () => {
    // Handle form data (e.g., send to API)
    console.log("Form data:", sections);
  };

  return (
    <div className="scope-of-work mt-5">
      <form>
        {sections.map((section) => (
          <div className="mb-4" key={section.id}>
            <label
              htmlFor={section.id}
              className="form-label bg-white d-flex justify-content-between br-10 text-area-label"
            >
              <span className="text-left label-text-area">{section.label}</span>
              <a
                className="edit text-right"
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  toggleEdit(section.id);
                }}
              >
                <i className="fas fa-pen"></i>
              </a>
            </label>
            <textarea
              className="form-control br-10 mb-2 p-3"
              id={section.id}
              placeholder="Write Details"
              rows="3"
              value={section.content}
              onChange={(e) => handleTextChange(e, section.id)}
              readOnly={!section.isEditable} // Set readOnly based on edit mode
            />
            <div className="delete-btn">
              <button
                type="button"
                className="btn btn-link"
                onClick={() => handleDelete(section.id)}
              >
                Delete
              </button>
            </div>
          </div>
        ))}
        <div className="mb-4">
          <div className="cardbg-form-group">
            <div className="attech-file-cover">
              <a href="" className="attech-btn">
                 <img src={attechFile} alt="comp1" className="attechFile" />
                Attach Files
              </a>
              <a href="" className="add-btn-text">
                <input type="file" id="myFile" name="filename"/>
                + Add
              </a>
            </div>
            <div className="user-box-cover">
              <div className="username">
                 <img src={filePic} alt="comp1" className="filePic" />
                <div className="username-content">
                  <p>hero banner.jpg</p>
                  <small>553KB</small>
                </div>
              </div>
               <img src={deleteBtn} alt="comp1" className="deleteBtn" />
            </div>
            <div className="user-box-cover">
              <div className="username">
                 <img src={filePic} alt="comp1" className="filePic" />
                <div className="username-content">
                  <p>hero banner.jpg</p>
                  <small>553KB</small>
                </div>
              </div>
               <img src={deleteBtn} alt="comp1" className="deleteBtn" />
            </div>
          </div>
        </div>
        <div className="d-grid mt-5 gap-3">
          <button
            className="add-new-section"
            type="button"
            onClick={addNewSection}
          >
            + Add new section
          </button>
          <button className="btn-black" type="button" onClick={handleSubmit}>
            Next
          </button>
        </div>
      </form>
      <div className="py-4">
        <a href="#" className="link link-color">
          {/* Let's Skip it for now, and I'll add the details later. */}
          Skip
        </a>
      </div>
    </div>
  );
};

export default ScopeOfWork;
