import React, { useState, useEffect } from "react";
import Select from "react-select";
import PhoneNumberInput from "../../../components/PhoneInput";
import { useAuth } from "../../../contexts/AuthContext";
import APIServices from "../../../services/APIServices";
import appToast, { TOAST_TYPE } from "../../../utils/AppToast";
import {
  getformattedPhoneNumber,
  getPlainPhoneNumber,
  selectLoader,
} from "../../../utils/Helpers";

const UserForm = ({ initialData, roleOptions, closeModal, orgId = "" }) => {
  const { user } = useAuth();
  const [error, setError] = useState();
  const [organizationId, setOrganizationId] = useState("");
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    userRoleName: null,
    countryDialCode: "",
    countryCode: "US",
  });
  const [memberId, setMemberId] = useState("");
  const [loading, setLoading] = useState(false);
  const [roleData, setRoleData] = useState([]);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (orgId) {
      setOrganizationId(orgId);
    }
  }, [orgId]);

  useEffect(() => {
    if (initialData) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        fullName: initialData.fullName || "",
        email: initialData.email || "",
        phoneNumber: getformattedPhoneNumber(initialData.phoneNumber) || "",
        userRoleName: initialData.userType.role,
        countryDialCode: initialData.countryDialCode || "",
        countryCode: initialData.countryCode || "US",
        userId: initialData._id,
      }));
      setMemberId(initialData._id);
    }
  }, [initialData]);

  useEffect(() => {
    if (roleOptions) {
      setRoleData(roleOptions);
    }
  }, [roleOptions]);

  useEffect(() => {
    if (user && !initialData) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        inviterId: user._id,
      }));
    }
  }, [user]);

  // Handle input change and clear errors
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    if (errors[name]) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };

  const handlePhoneChange = (val, countryDialCode, countryCode) => {
    setFormData({
      ...formData,
      phoneNumber: val,
      countryDialCode: `${countryDialCode}`,
      countryCode: countryCode,
    });

    // Clear the error message for the current field being changed
    setErrors({
      ...errors,
      phoneNumber: "",
    });
  };

  // Handle select change for Role
  const handleSelectChange = (selected) => {
    setFormData((prevData) => ({ ...prevData, userRoleName: selected.value }));
    if (errors.userRoleName) {
      setErrors((prevErrors) => ({ ...prevErrors, userRoleName: "" }));
    }
  };

  // Form Validation
  const validateForm = () => {
    let formErrors = {};

    if (!formData.fullName.trim()) {
      formErrors.fullName = "Organization Name is required";
    }
    if (!formData.email.trim()) {
      formErrors.email = "Email Address is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      formErrors.email = "Invalid email address";
    }
    if (
      !getPlainPhoneNumber(formData.phoneNumber).trim() ||
      getPlainPhoneNumber(formData.phoneNumber).length < 10
    ) {
      formErrors.phoneNumber = "Not enough digits for a phone number.";
    }
    if (!formData.userRoleName) {
      formErrors.userRoleName = "Role is required";
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const endpoint = memberId ? `/team/member` : "/user/invite-user";
    if (validateForm()) {
      setLoading(true);
      // Submit form data
      let plainNumber = getPlainPhoneNumber(formData.phoneNumber);
      const updatedFormValues = {
        ...formData,
        phoneNumber: plainNumber,
        organizationId
      };
      try {
        const response = memberId
          ? await APIServices.put(endpoint, updatedFormValues)
          : await APIServices.post(endpoint, updatedFormValues);
        if (response.message) {
          appToast(response.message, TOAST_TYPE.SUCCESS);
        } else {
          appToast(response.data.message, TOAST_TYPE.ERROR);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
        closeModal();
      }
      if (!memberId) {
        // Reset form
        // setFormData({
        //   fullName: "",
        //   email: "",
        //   phoneNumber: "",
        //   userRoleName: null,
        //   countryDialCode: "",
        //   countryCode: "US",
        //   inviterId: "",
        // });
        setErrors({});
      }
      console.log(formData);
    }
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-lg-12">
          <div className="form-group">
            <input
              type="text"
              className={`form-control ${
                errors.fullName ? "form-invalid" : ""
              }`}
              name="fullName"
              placeholder="User Name"
              value={formData.fullName}
              onChange={handleInputChange}
            />
            {errors.fullName && (
              <small className="error-message">{errors.fullName}</small>
            )}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="form-group">
            <input
              type="text"
              className={`form-control ${errors.email ? "form-invalid" : ""}`}
              name="email"
              placeholder="Email Address"
              value={formData.email}
              onChange={handleInputChange}
            />
            {errors.email && (
              <small className="error-message">{errors.email}</small>
            )}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="form-group">
            <PhoneNumberInput
              isDisable={false}
              value={formData.phoneNumber}
              onPhoneChange={(val) =>
                handlePhoneChange(val, formData.countryDialCode)
              }
              countryDialCode={formData.countryDialCode}
              countryName={formData.countryCode}
              onCountryChange={(country) => {
                setFormData({
                  ...formData,
                  countryDialCode: country.dialCode,
                  countryCode: country.value,
                });
                handlePhoneChange(
                  formData.phoneNumber,
                  country.dialCode,
                  country.value
                );
              }}
              errors={errors.phoneNumber}
            />
            {errors.phoneNumber && (
              <small className="error-message">{errors.phoneNumber}</small>
            )}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="form-group">
            <Select
              className={`form-control select2 form-control-select ${
                errors.userRoleName ? "form-invalid" : ""
              }`}
              options={roleData}
              value={roleData.find(
                (option) => option.value === formData.userRoleName
              )}
              onChange={handleSelectChange}
              placeholder="Role"
              styles={{ width: "100%" }}
            />
            {errors.userRoleName && (
              <small className="error-message">{errors.userRoleName}</small>
            )}
          </div>
        </div>
      </div>

      <button className="btn-black">
        {!loading ? (memberId ? "Edit" : "Add") : selectLoader(35)}
      </button>
    </form>
  );
};

export default UserForm;
