import APIServices from "../services/APIServices";

export const fetchRolesList = async (setRoles, setLoading, setError) => {
  setLoading(true);
  try {
    const response = await APIServices.get(`/user-roles`); // Adjust the URL as necessary
    if (response.userRoles) {
      const mappedRoles = response.userRoles.map((role) => ({
        value: role.role, // Use 'role' as the value
        label: role.name, // Use 'name' as the label
      }));
      setRoles(mappedRoles);
    }
  } catch (error) {
    setError(error.message);
  } finally {
    setLoading(false);
  }
};

export const fetchPermissions = async (setPermissions) => {
  try {
    const response = await APIServices.get("/permissions"); // Adjust the URL as necessary

    if (response.length > 0) {
      const permissionsData = response;

      const formattedPermissions = permissionsData.map((permission) => ({
        value: permission._id, // or permission._id if needed
        label: permission.name,
      }));

      setPermissions(formattedPermissions);
    }
  } catch (error) {
    console.error("Error fetching permissions:", error);
  }
};

export const fetchTeamMembers = async (setTeams, setLoading, teamId) => {
  let membersOptions = [];
  try {
    const response = await APIServices.get(`/team/${teamId}?status=accepted`);
    if (response.members) {
      membersOptions = response.members.map((member) => ({
        value: member._id, // or permission._id if needed
        label: member.fullName,
        role: member.userType.role,
      }));
      setTeams(membersOptions || []);
    } else if (response?.data?.message) {
      setTeams([]);
    }
  } catch (error) {
    console.error("Error fetching the teams:", error);
  } finally {
    setLoading(false);
  }
};
