import React, { useEffect, useState, useRef } from "react";
import { Button } from "react-bootstrap";
import { Container } from "react-floating-action-button";
import { Link } from "react-router-dom";

const ActionMenu = ({
  extraClass = "",
  links,
  buttonTooltip,
  buttonIcon,
  buttonStyles,
  showItem,
}) => {
  const [showActionItems, setShowItems] = useState(false);
  const menuRef = useRef(null); // Create a ref to the menu container

  useEffect(() => {
    setShowItems(showItem);
  }, [showItem]);

  // Function to toggle menu visibility
  const toggleActions = () => {
    setShowItems((prev) => !prev);
  };

  // Function to handle clicks outside the menu
  const handleClickOutside = (event) => {
    // Check if the click is outside the menu but also not related to the Joyride tutorial
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      const isJoyrideClick = event.target.closest(".__floater"); // Assuming Joyride buttons have a class 'joyride'
      if (!isJoyrideClick) {
        setShowItems(false);
      }
    }
  };

  // Add event listener for clicks outside the menu
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="add-project" ref={menuRef}>
      <div className={`options-list-menu ${extraClass}`}>
        <div className="options-list-item">
          {links.map((link, index) => (
            <Link
              to={link.linkto}
              className={`${link.className} ${showItem ? "show-items" : ""}`}
              key={index}
              style={{ display: showActionItems ? "block" : "none" }}
            >
              {link.text}
            </Link>
          ))}
        </div>

        <Button
          icon={buttonIcon}
          rotate={true}
          styles={buttonStyles}
          onClick={toggleActions}
          className={`add-btn ${showActionItems ? "rotate" : ""}`}
        >
          <i className="fas fa-plus"></i>
        </Button>
      </div>
    </div>
  );
};

export default ActionMenu;
