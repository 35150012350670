import React, { createContext, useContext, useEffect, useState } from "react";
import { getCookies, setCookies } from "../utils/Helpers";
import APIServices from "../services/APIServices";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(getCookies("authToken"));
  const [user, setUser] = useState(false);
  const [inviterId, setInviterId] = useState("");

  const fetchUserInfo = async () => {
    if (token) {
      let userRes = await APIServices.get(`/user/detail?token=${token}`);
      setUser(userRes.data);
      setInviterId(userRes?.data?._id);
      return userRes.data;
    }
  };

  useEffect(() => {
    if (!token) return;
    fetchUserInfo();
  }, [token]);

  const logout = () => {
    setUser(null);
    setCookies("authToken", null);
    refreshToken("");
  };

  // If user logoff or login, update token from child component
  const refreshToken = (newToken) => {
    setToken(newToken);
  };

  return (
    <AuthContext.Provider
      value={{ user, refreshToken, logout, token, fetchUserInfo ,inviterId}}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
